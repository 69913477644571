import { Button, Modal, Box, Typography, TableCell } from '@mui/material';

type SensorData = {
  deviceId: string;
  NrSensors: number;
  [key: string]: string | number; // Dynamic keys for sensor names, values, and units
};

type RDMSensorModalProps = {
  open: boolean;
  onClose: () => void;
  deviceId: string;
  data: SensorData[];
};

export default function RDMSensorModal({
  open,
  onClose,
  deviceId,
  data,
}: RDMSensorModalProps) {
  const foundData = data.find((device) => device.deviceId === deviceId);

  const renderSensors = () => {
    if (!foundData) return null;

    const sensors = [];
    for (let i = 0; i < foundData.NrSensors; i++) {
      const nameKey = `Sen${i}Name`;
      const valueKey = `Sen${i}Value`;
      const unitKey = `Sen${i}Unit`;

      const sensorName = foundData[nameKey] as string | undefined;
      const sensorValue = foundData[valueKey] as string | number | undefined;
      const sensorUnit = foundData[unitKey] as string | undefined;

      if (sensorName && sensorValue && sensorUnit) {
        sensors.push(
          <TableCell key={`sensor-${i}`}>
            {sensorName}: {sensorValue}{' '}
            {sensorUnit.charAt(0).toUpperCase() +
              sensorUnit.slice(1).toLowerCase()}
          </TableCell>,
        );
      }
    }
    return sensors;
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="rdm-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
        }}
      >
        <Typography id="rdm-modal-title" variant="h6" sx={{ mb: 2 }}>
          Sensor Data - Device 0x{parseInt(deviceId).toString(16)}
        </Typography>

        {!foundData ? <>No Sensors Detected!</> : <>{renderSensors()}</>}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
