import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../../context/UserStateManager';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import {
  deleteRDMDevice,
  discoverRDM,
  getRDMStatus,
  identifyRdm,
  setRDM,
} from './rdmHelpers';
import RDMSendModal from './RDMSendModal';
import RDMDiscover from './RDMDiscover';
import { Delete, Edit, Save } from '@mui/icons-material';
import RDMSensorModal from './RDMSensorModal';

export default function RDMPage() {
  const userCon = useContext(UserContext);
  const queryClient = useQueryClient();

  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [currentCustomModalDevice, setCurrentCustomModalDevice] = useState('');
  const [discoverModalOpen, setDiscoverModalOpen] = useState(false);
  const [sensorModalOpen, setSensorModalOpen] = useState(false);
  const [currentSensorModalDevice, setSensorCustomModalDevice] = useState('');
  const [currentIdentifying, setCurrentIdentifying] = useState<String[]>([]);
  const [currentEditing, setCurrentEditing] = useState<String[]>([]);

  const [editingState, setEditingState] = useState<
    {
      deviceId: string;
      tempDmx?: number;
      tempPersonality?: number;
    }[]
  >([]);

  const rdmStatus = useQuery(
    'rdmStatus',
    async () => await getRDMStatus(userCon),
    {
      staleTime: 360000,
      enabled: !!userCon.state.currentDevice?.rdmDevices,
    },
  );

  const discover = useMutation(
    async () => {
      return await discoverRDM(userCon);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('get-devices');
        setDiscoverModalOpen(false);
        queryClient.invalidateQueries('rdmStatus');
        toast.success('Completed Discovery!');
      },
    },
  );

  const { mutate: updateRDM } = useMutation(
    async ({
      param,
      devId,
      value,
    }: {
      param: string;
      devId: string;
      value: string;
    }) => {
      return await setRDM(userCon, param, devId, value);
    },
    {
      onError: (error) => {
        console.error('Error updating RDM:', error);
      },
      onSuccess: () => {
        queryClient.refetchQueries('rdmStatus');
        toast.success('Updated Device Settings');
      },
    },
  );

  const rdmIdentify = useMutation(
    async ({
      mfr,
      devId,
      start,
    }: {
      mfr: string;
      devId: string;
      start: boolean;
    }) => {
      return await identifyRdm(userCon, devId, mfr, start);
    },
    {
      onError: (error) => {
        console.error('Error identifying device:', error);
      },
    },
  );

  const deleteRDM = useMutation(
    async ({ devId }: { devId: string }) => {
      return await deleteRDMDevice(userCon, devId);
    },
    {
      onError: (error) => {
        console.error('Error deleting RDM device:', error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('get-devices');
        queryClient.refetchQueries('rdmStatus');
        toast.success('Updated RDM Devices');
      },
    },
  );

  if (
    !userCon.state.currentDevice?.rdmDevices.length ||
    userCon.state.currentDevice.rdmDevices.length === 0 ||
    Boolean(userCon.state.currentDevice.rdmDevices[0]) === false
  ) {
    return (
      <Paper
        style={{ marginBottom: 16 }}
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        <Typography variant="h3">No Devices!</Typography>
        <span>There are currently no RDM capable devices saved.</span>
        <Tooltip title="Search for RDM capable devices." arrow>
          <Button
            sx={{ width: '180px', height: '40px' }}
            color="primary"
            variant="contained"
            onClick={() => setDiscoverModalOpen(true)}
          >
            Discover Devices
          </Button>
        </Tooltip>
        <RDMDiscover
          open={discoverModalOpen}
          onClose={() => setDiscoverModalOpen(false)}
          isLoading={discover.isLoading}
          handleSubmit={() => discover.mutate()}
        />
      </Paper>
    );
  }

  if (!rdmStatus.data) {
    return (
      <div
        style={{
          display: 'flex',
          marginTop: '150px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Paper style={{ marginBottom: 16 }}>
        <Typography variant="h3">Device Details</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Device Model</TableCell>
                <TableCell>DMX Footprint</TableCell>
                <TableCell>Personality Count</TableCell>
                <TableCell>DMX Address</TableCell>
                <TableCell>Personality</TableCell>
                <TableCell>Sensors</TableCell>
                <TableCell>Identify</TableCell>
                <TableCell>Custom Command</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userCon.state.currentDevice.rdmDevices.map((deviceId: any) => {
                const matchingDevice = rdmStatus.data.find(
                  (device: any) => device.deviceId === deviceId,
                );

                const isDeviceEditing = currentEditing.includes(
                  matchingDevice.deviceId,
                );

                if (matchingDevice) {
                  return (
                    <TableRow sx={{ color: 'red' }}>
                      <TableCell>
                        0x{parseInt(matchingDevice.deviceId).toString(16)}
                      </TableCell>
                      <TableCell>{matchingDevice.DeviceModel}</TableCell>
                      <TableCell>{matchingDevice.DMXFootprint}</TableCell>
                      <TableCell>{matchingDevice.NrPersonalities}</TableCell>
                      <TableCell>
                        {isDeviceEditing ? (
                          <>
                            <TextField
                              size="small"
                              variant="outlined"
                              sx={{ width: '60px' }}
                              defaultValue={matchingDevice.startAddr}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                setEditingState((prevState) =>
                                  prevState.map((state) =>
                                    state.deviceId === matchingDevice.deviceId
                                      ? { ...state, tempDmx: newValue }
                                      : state,
                                  ),
                                );
                              }}
                            />
                          </>
                        ) : (
                          matchingDevice.startAddr
                        )}
                      </TableCell>

                      <TableCell>
                        {isDeviceEditing ? (
                          <>
                            <TextField
                              size="small"
                              variant="outlined"
                              defaultValue={matchingDevice.personality}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                setEditingState((prevState) =>
                                  prevState.map((state) =>
                                    state.deviceId === matchingDevice.deviceId
                                      ? { ...state, tempPersonality: newValue }
                                      : state,
                                  ),
                                );
                              }}
                            />
                          </>
                        ) : (
                          matchingDevice.personality
                        )}
                      </TableCell>

                      <TableCell>
                        <Button
                          onClick={() => {
                            setSensorCustomModalDevice(deviceId);
                            setSensorModalOpen(true);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Sensors
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            const includes = currentIdentifying.includes(
                              matchingDevice.deviceId,
                            );
                            if (includes) {
                              // Remove the variable if it exists in the array
                              setCurrentIdentifying((prev) =>
                                prev.filter(
                                  (deviceId) =>
                                    deviceId !== matchingDevice.deviceId,
                                ),
                              );
                            } else {
                              // Add the variable if it does not exist in the array
                              setCurrentIdentifying((prev) => [
                                ...prev,
                                matchingDevice.deviceId,
                              ]);
                            }
                            rdmIdentify.mutate({
                              mfr: '18247',
                              devId: deviceId,
                              start: !includes,
                            });
                          }}
                          color={
                            currentIdentifying.includes(matchingDevice.deviceId)
                              ? 'warning'
                              : 'primary'
                          }
                          variant="contained"
                        >
                          {currentIdentifying.includes(matchingDevice.deviceId)
                            ? 'Stop'
                            : 'Identify'}{' '}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            setCurrentCustomModalDevice(deviceId);
                            setCustomModalOpen(true);
                          }}
                          color="secondary"
                          variant="contained"
                        >
                          Custom
                        </Button>
                      </TableCell>
                      <TableCell>
                        {isDeviceEditing ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setCurrentEditing((prev) =>
                                prev.filter(
                                  (deviceId) =>
                                    deviceId !== matchingDevice.deviceId,
                                ),
                              )
                            }
                          >
                            <Tooltip title="Save Device">
                              <Save
                                color="primary"
                                onClick={() => {
                                  // Find the editing state for this device
                                  const currentState = editingState.find(
                                    (state) =>
                                      state.deviceId ===
                                      matchingDevice.deviceId,
                                  );

                                  if (currentState) {
                                    // Save updated DMX Address if it exists
                                    if (
                                      currentState.tempDmx !== undefined &&
                                      matchingDevice.startAddr !==
                                        currentState.tempDmx
                                    ) {
                                      updateRDM({
                                        param: '0x00f0',
                                        devId: matchingDevice.deviceId,
                                        value: currentState.tempDmx.toString(),
                                      });
                                    }

                                    // Save updated Personality if it exists
                                    if (
                                      currentState.tempPersonality !==
                                        undefined &&
                                      matchingDevice.personality !==
                                        currentState.tempPersonality
                                    ) {
                                      updateRDM({
                                        param: '0x00e0',
                                        devId: matchingDevice.deviceId,
                                        value:
                                          currentState.tempPersonality.toString(),
                                      });
                                    }
                                  }

                                  // Exit editing mode and clear editing state
                                  setCurrentEditing((prev) =>
                                    prev.filter(
                                      (deviceId) =>
                                        deviceId !== matchingDevice.deviceId,
                                    ),
                                  );

                                  setEditingState((prevState) =>
                                    prevState.filter(
                                      (state) =>
                                        state.deviceId !==
                                        matchingDevice.deviceId,
                                    ),
                                  );
                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Delete Device">
                              <Delete
                                color="warning"
                                onClick={() => {
                                  deleteRDM.mutate({
                                    devId: matchingDevice.deviceId,
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <Tooltip title="Edit Device">
                            <Edit
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                // Enter editing mode for the device
                                setCurrentEditing((prev) => [
                                  ...prev,
                                  matchingDevice.deviceId,
                                ]);

                                // Add or initialize editing state for the device
                                setEditingState((prevState) => {
                                  const existingState = prevState.find(
                                    (state) =>
                                      state.deviceId ===
                                      matchingDevice.deviceId,
                                  );
                                  if (existingState) {
                                    return prevState; // Device is already being edited
                                  }

                                  return [
                                    ...prevState,
                                    {
                                      deviceId: matchingDevice.deviceId,
                                      tempDmx: matchingDevice.startAddr,
                                      tempPersonality:
                                        matchingDevice.personality,
                                    },
                                  ];
                                });
                              }}
                            />
                          </Tooltip>
                        )}{' '}
                      </TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow key={deviceId}>
                    <TableCell>0x{parseInt(deviceId).toString(16)}</TableCell>
                    <TableCell colSpan={6} sx={{ color: 'red' }}>
                      Error - Device Not Responding
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper>
        <Typography variant="h3" style={{ marginBottom: '10px' }}>
          Diagnostics
        </Typography>

        <Tooltip title="Search for devices again" arrow>
          <Button
            sx={{ width: '180px', height: '40px' }}
            color="primary"
            variant="contained"
            onClick={() => setDiscoverModalOpen(true)}
          >
            Discover Devices
          </Button>
        </Tooltip>
      </Paper>

      <RDMDiscover
        open={discoverModalOpen}
        onClose={() => setDiscoverModalOpen(false)}
        isLoading={discover.isLoading}
        handleSubmit={() => discover.mutate()}
      />

      <RDMSendModal
        open={customModalOpen}
        onClose={() => setCustomModalOpen(false)}
        deviceId={currentCustomModalDevice}
      />

      <RDMSensorModal
        open={sensorModalOpen}
        onClose={() => setSensorModalOpen(false)}
        deviceId={currentSensorModalDevice}
        data={rdmStatus.data}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
