import {
  Button,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
const RDMDiscover = ({
  open,
  onClose,
  handleSubmit,
  isLoading,
}: {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="rdm-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
        }}
      >
        <Typography id="rdm-modal-title" variant="h6" sx={{ mb: 2 }}>
          Discover Devices
        </Typography>

        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70px',
              fontWeight: 'bold',
            }}
          >
            Running Discovery (this may take some time).
            <CircularProgress />
          </div>
        ) : (
          <p>
            Are you sure you want to rediscover your devices? Doing so will
            delete all saved devices and only remember what it gets now.
          </p>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: '30px',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Discover
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RDMDiscover;
