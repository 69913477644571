import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { getRDM, setRDM } from './rdmHelpers';
import { UserContext } from '../../context/UserStateManager';

const RDMSendModal = ({
  open,
  onClose,
  deviceId,
}: {
  open: boolean;
  onClose: () => void;
  deviceId: string;
}) => {
  const [param, setParam] = useState('');
  const [customParam, setCustomParam] = useState('');
  const [value, setValue] = useState('');
  const [commandType, setCommandType] = useState<'GET' | 'SET' | ''>('');
  const [getResponse, setGetResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const userCon = useContext(UserContext);
  const queryClient = useQueryClient();

  type Param = {
    value: number;
    returnType: string;
    commandType: 'GET' | 'SET' | 'GET_SET';
  };

  type Params = {
    [key: string]: Param;
  };

  const params: Params = {
    QUEUED_MESSAGE: {
      value: 0x0020,
      returnType: 'QUEUED_MESSAGE',
      commandType: 'GET',
    },
    SUPPORTED_PARAMETERS: {
      value: 0x0050,
      returnType: 'INT16LIST',
      commandType: 'GET',
    },
    PARAMETER_DESCRIPTION: {
      value: 0x0051,
      returnType: 'PARAM_DESC',
      commandType: 'GET',
    },
    DEVICE_INFO: {
      value: 0x0060,
      returnType: 'DEVICE_INFO',
      commandType: 'GET',
    },
    DEVICE_MODEL_DESCRIPTION: {
      value: 0x0080,
      returnType: 'STRING',
      commandType: 'GET',
    },
    MANUFACTURER_LABEL: {
      value: 0x0081,
      returnType: 'STRING',
      commandType: 'GET',
    },
    DEVICE_LABEL: {
      value: 0x0082,
      returnType: 'STRING',
      commandType: 'GET_SET',
    },
    SOFTWARE_VERSION_LABEL: {
      value: 0x00c0,
      returnType: 'STRING',
      commandType: 'GET',
    },
    DMX_PERSONALITY: {
      value: 0x00e0,
      returnType: 'INT8',
      commandType: 'GET_SET',
    },
    DMX_PERSONALITY_DESCRIPTION: {
      value: 0x00e1,
      returnType: 'PERSON_DESC',
      commandType: 'GET',
    },
    DMX_START_ADDRESS: {
      value: 0x00f0,
      returnType: 'INT16',
      commandType: 'GET_SET',
    },
    IDENTIFY_DEVICE: {
      value: 0x1000,
      returnType: 'null',
      commandType: 'GET',
    },
    SENSOR_DEFINITION: {
      value: 0x0200,
      returnType: 'SENSOR_DEFINITION',
      commandType: 'GET',
    },
    SENSOR_VALUE: {
      value: 0x0201,
      returnType: 'SENSOR_VALUE',
      commandType: 'GET',
    },
    LAMP_ON_MODE: {
      value: 0x0404,
      returnType: 'LAMP_ON_MODE',
      commandType: 'GET_SET',
    },
    'G&G - Fixture Style': {
      value: 0x80e2,
      returnType: 'INT16',
      commandType: 'GET_SET',
    },
    'G&G - On DMX Lost': {
      value: 0x80e0,
      returnType: 'INT16',
      commandType: 'GET_SET',
    },
    'G&G - Over The Air Updates': {
      value: 0x8403,
      returnType: 'INT16',
      commandType: 'GET_SET',
    },
    CUSTOM: { value: 0, returnType: 'null', commandType: 'GET_SET' },
  };

  const handleParamChange = (selectedParam: string) => {
    setParam(selectedParam);
    setCustomParam('');
    setValue('');

    if (params[selectedParam].commandType === 'GET') {
      setCommandType('GET');
    } else if (params[selectedParam].commandType === 'SET') {
      setCommandType('SET');
    } else {
      setCommandType('');
    }
  };

  const { mutate: sendRDM, isLoading } = useMutation(
    async () => {
      const selectedParam = params[param];
      const paramValue =
        !customParam || customParam === '' ? selectedParam?.value : customParam;

      if (commandType === 'SET') {
        return await setRDM(userCon, String(paramValue), deviceId, value);
      } else {
        setLoading(true);
        const response = await getRDM(
          userCon,
          String(paramValue),
          deviceId,
          value,
        );
        setGetResponse(JSON.stringify(response, null, 2));
        setLoading(false);
      }
    },
    {
      onError: (error) => {
        console.error('Error processing RDM command:', error);
        setLoading(false);
      },
      onSuccess: () => {
        // queryClient.invalidateQueries('rdmStatus');
        setCustomParam('');
        setCommandType('');
        setParam('');
        setValue('');
      },
    },
  );

  const handleClose = () => {
    setGetResponse(null);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="rdm-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
        }}
      >
        {getResponse ? (
          // GET Response Page
          <>
            <Typography id="rdm-modal-title" variant="h6" sx={{ mb: 2 }}>
              GET Response
            </Typography>
            <Box
              sx={{
                bgcolor: '#f5f5f5',
                padding: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                maxHeight: 300,
                overflowY: 'auto',
              }}
            >
              <pre>{getResponse}</pre>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </>
        ) : (
          // Command Input Page
          <>
            <Typography id="rdm-modal-title" variant="h6" sx={{ mb: 2 }}>
              Custom Command - Device 0x{parseInt(deviceId).toString(16)}
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="param-label">Parameter</InputLabel>
              <Select
                labelId="param-label"
                value={param}
                onChange={(e) => handleParamChange(e.target.value)}
              >
                {Object.keys(params).map((paramOption) => (
                  <MenuItem key={paramOption} value={paramOption}>
                    {paramOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} disabled={!param}>
              <InputLabel id="command-type-label">Command Type</InputLabel>
              <Select
                labelId="command-type-label"
                value={commandType}
                onChange={(e) =>
                  setCommandType(e.target.value as 'GET' | 'SET')
                }
              >
                {param &&
                  (params[param].commandType === 'GET' ||
                    params[param].commandType === 'GET_SET') && (
                    <MenuItem value="GET">GET</MenuItem>
                  )}
                {param &&
                  (params[param].commandType === 'SET' ||
                    params[param].commandType === 'GET_SET') && (
                    <MenuItem value="SET">SET</MenuItem>
                  )}
              </Select>
            </FormControl>

            {param === 'CUSTOM' && (
              <TextField
                label="Custom PID"
                fullWidth
                value={customParam}
                onChange={(e) => setCustomParam(e.target.value)}
                sx={{ mb: 2 }}
              />
            )}

            {(commandType === 'SET' || param === 'PARAMETER_DESCRIPTION') && (
              <TextField
                label="Value"
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={{ mb: 2 }}
              />
            )}

            {isLoading || loading ? (
              <CircularProgress
                sx={{ display: 'block', margin: 'auto', my: 2 }}
              />
            ) : null}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendRDM()}
                disabled={
                  !param ||
                  !commandType ||
                  ((commandType === 'SET' ||
                    param === 'PARAMETER_DESCRIPTION') &&
                    !value) ||
                  (!customParam && param === 'CUSTOM')
                }
              >
                {commandType === 'SET'
                  ? 'Send SET Command'
                  : 'Send GET Command'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default RDMSendModal;
