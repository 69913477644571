import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef, useState } from 'react';
import UserForm from './UserForm';
import { UserModel } from './Users';
import { OrgRole, OrgRoleMap, SysRole, SysRoleMap } from '../Enums';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';
import ConfirmationModal from '../common/ConfirmationModal';
import Papa from 'papaparse';

type AppProps = {
  admin?: boolean;
  users: UserModel[];
  getUsers: Function;
  saveUser: Function;
  updateUser: Function;
  deleteUser: Function;
  resetUserPassword: Function;
};

function UserList({
  admin,
  users,
  getUsers,
  saveUser,
  updateUser,
  deleteUser,
  resetUserPassword,
}: AppProps) {
  const userCon = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formState, setFormState] = useState<{
    open: boolean;
    type: null | Function;
    userID: null | string;
  }>({ open: false, type: null, userID: null });
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  function exportUsers() {
    const data = users
      .filter((user) => user.hasAcceptedInvite)
      .map((user) => ({
        Email: user.email,
        FirstName: user.firstName,
        LastName: user.lastName,
        Role: user.role,
        Orgs: user.orgs.map(({ org }) => org.name),
      }));
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const dl = document.createElement('a');
    dl.href = url;
    dl.setAttribute(
      'download',
      `colorwash_users_${new Date().toISOString()}.csv`,
    );
    dl.click();
    dl.remove();
  }

  const getCurrentUser = () =>
    users.filter((user) => user._id === formState.userID)[0] ?? null;

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: '',
      width: 40,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) =>
        admin &&
        userCon.state.user.role === SysRole.SYSTEM_MANAGER &&
        userCon.state.user._id !== params.row._id &&
        params.row.role !== SysRole.SYSTEM_USER ? (
          <div></div>
        ) : (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setFormState({ ...formState, userID: params.id as string });
            }}
            style={{ padding: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
    },
    {
      field: ' ',
      headerName: 'Name',
      width: 210,
      valueGetter: (params, row) =>
        row.hasAcceptedInvite
          ? `${row.firstName} ${row.lastName}`
          : '< Pending Invite >',
    },
    {
      field: 'role',
      headerName: 'User Type',
      width: 128,
      disableColumnMenu: true,
      valueGetter: (params, row) =>
        admin
          ? SysRoleMap[row.role as SysRole]
          : OrgRoleMap[
              row.orgs.filter(
                (ele: any) =>
                  ele.org._id === userCon.state.currentOrg?.org?._id,
              )[0]?.role as OrgRole
            ],
    },
  ];

  return (
    <Paper className="AdminContentContainer">
      <Typography variant="h2">Users</Typography>
      <div className="AdminTableContainer">
        <DataGrid
          autoPageSize
          density="compact"
          rows={users}
          columns={columns}
          getRowId={(row) => row._id}
          ref={(n) => {
            tableRef.current = n;
          }}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            getUsers();
            setAnchorEl(null);
            setFormState({
              ...formState,
              open: true,
              type: updateUser,
            });
          }}
        >
          <Typography>Edit User</Typography>
        </MenuItem>
        {userCon.state.user._id !== getCurrentUser()?._id && (
          <MenuItem
            color="error"
            onClick={() => {
              setConfirmModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <Typography color="error">{admin ? 'Delete' : 'Remove'}</Typography>
          </MenuItem>
        )}
      </Menu>
      <div
        style={{
          padding: '16px 0',
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            setFormState({ open: true, type: saveUser, userID: null })
          }
        >
          Invite User
        </Button>
        {admin && (
          <Button color="primary" variant="contained" onClick={exportUsers}>
            Export User List
          </Button>
        )}
      </div>
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          setConfirmModalOpen(false);
          deleteUser(getCurrentUser(), userCon.state.currentOrg?.org?._id);
        }}
        title={(admin ? 'Delete' : 'Remove') + ' User?'}
        message={`Are you sure you want to ${
          admin ? 'delete' : 'remove'
        } this user?`}
        confirmText={admin ? 'Delete' : 'Remove'}
      />

      <Dialog
        fullScreen
        open={formState.open}
        onClose={() => setFormState({ open: false, type: null, userID: null })}
      >
        {formState.type !== null && (
          <UserForm
            admin={admin}
            userData={getCurrentUser()}
            submitUser={formState.type}
            closeDialog={() =>
              setFormState({ open: false, type: null, userID: null })
            }
            resetUserPassword={resetUserPassword}
          />
        )}
      </Dialog>
    </Paper>
  );
}

export default UserList;
