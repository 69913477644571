import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  TableHead,
  TablePagination,
  TableContainer,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { DeviceModel } from '../../devices/Devices';
import { GroupTreeNode } from '../orgTypes';
import { DeviceTableRow } from './DeviceTableRow';
import { GroupTableRow } from './GroupTableRow';
import { UserContext } from '../../context/UserStateManager';

interface OrgGroupsTableProps {
  groupTree: GroupTreeNode[];
  groups: GroupTreeNode[];
  devices?: DeviceModel[];
  openSettings: Function;
  setEditGroup: Function;
}

export default function OrgGroupsTable({
  groupTree,
  groups,
  openSettings,
  devices,
  setEditGroup,
}: OrgGroupsTableProps) {
  const userCon = useContext(UserContext);
  const [rowOpen, setRowOpen] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setRowOpen([]);
  }, [userCon.state.currentOrg?.org?._id]);

  function handleRowOpen(key: string) {
    if (rowOpen.includes(key))
      setRowOpen((l) => l.filter((item) => item !== key));
    else setRowOpen((open) => [...open, key]);
  }

  // Combined items for pagination
  const combinedItems = [...groups, ...(devices || [])];

  const paginatedItems = combinedItems.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell width={1}>Type</TableCell>
          <TableCell colSpan={6}>Name</TableCell>
          <TableCell width={1}></TableCell>
          <TableCell width={1}></TableCell>
        </TableHead>
        <TableBody>
          {paginatedItems.map((item, key) => {
            if (Object.keys(item).includes('children')) {
              // Render group row
              const groupNode = item as GroupTreeNode;
              return (
                <>
                  <TableRow key={groupNode.group._id}>
                    <GroupTableRow
                      groupNode={groupNode}
                      groupId={groupNode.group._id}
                      openSettings={openSettings}
                      setEditGroup={setEditGroup}
                      handleRowOpen={() => handleRowOpen(groupNode.group._id)}
                      rowOpen={rowOpen}
                    />
                  </TableRow>

                  {(groupNode.children.length > 0 ||
                    (groupNode.group.devices &&
                      groupNode.group.devices.length > 0)) && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        <Collapse
                          in={rowOpen.includes(groupNode.group._id)}
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <OrgGroupsTable
                              groups={groupNode.children}
                              devices={groupNode.group.devices}
                              openSettings={openSettings}
                              setEditGroup={setEditGroup}
                              groupTree={groupTree}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            } else {
              // Render device row
              const device = item as DeviceModel;
              return (
                <TableRow key={device._id}>
                  <DeviceTableRow device={device} />
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={combinedItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event: any) => {
          setRowsPerPage(parseInt(event?.target.value, 10));
          setPage(0);
        }}
      />
    </TableContainer>
  );
}
