import { Button, Paper, TextField, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import useTitle from '../../hooks/useTitle.hook';
import { toast, ToastContainer } from 'react-toastify';

type tUserEmail = { email: string };

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const { handleSubmit, control } = useForm<tUserEmail>();
  useTitle('Reset Password');

  const submit = async (userEmail: tUserEmail) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userEmail),
    });

    toast.success(
      'A password reset link has been sent to your email. Redirecting you to the login page...',
    );

    setTimeout(() => {
      history.push('/login');
    }, 4000);
  };

  return (
    <div
      style={{
        margin: 'calc(40vh - 190px) auto',
        width: '312px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img style={{ width: '312px' }} src={colorwashLogo} alt="Colorwash" />
      <Paper
        sx={{
          marginTop: theme.spacing(4),
          '& form': {
            display: 'flex',
            height: '145px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& button': {
              // margin: '0 auto',
              width: 'fit-content',
            },
            '& h1': {
              fontSize: '18px',
              fontWeight: 'bold',
            },
          },
        }}
      >
        <form onSubmit={handleSubmit((userSubmit) => submit(userSubmit))}>
          <Typography variant="h1">Reset Password</Typography>

          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email Address"
                type="email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Required',
              maxLength: {
                value: 320,
                message: 'Must be less than 320 characters',
              },
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button color="primary" type="submit" variant="contained">
              Continue
            </Button>
          </div>
        </form>
      </Paper>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ForgotPasswordPage;
