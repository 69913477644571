import { fetchLogoutOn401 } from '../../common/Handle401Fetch';
import { tUserContext } from '../../context/UserStateManager';

export async function getRDMStatus(userCon: tUserContext) {
  if (!userCon.state.currentDevice) return;

  const devices = userCon.state.currentDevice.rdmDevices.map((deviceId) => ({
    deviceId: deviceId,
  }));

  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/rdmStatus`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        devices: devices,
      }),
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');

  const body = await res.json();
  return body;
}
export async function getRDM(
  userCon: tUserContext,
  param: string,
  devId: string,
  value?: string,
) {
  if (!userCon.state.currentDevice) return;

  console.log(value);

  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/rdmGet`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        param: param,
        devId: devId,
        value: value,
      }),
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');

  const body = await res.json();

  console.log(body);
  return body;
}

export async function setRDM(
  userCon: tUserContext,
  param: string,
  devId: string,
  value: string,
) {
  if (!userCon.state.currentDevice) return;

  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/rdmSet`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        param: param,
        devId: devId,
        value: value,
      }),
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');

  return res.ok;
}

export async function identifyRdm(
  userCon: tUserContext,
  devId: string,
  mfr: string,
  start: boolean,
) {
  if (!userCon.state.currentDevice) return;

  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/rdmIdentify`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        devId: devId,
        mfr: mfr,
        start: start,
      }),
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');

  return res.ok;
}

export async function discoverRDM(userCon: tUserContext) {
  if (!userCon.state.currentDevice) return;

  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/discoverRDM`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');

  const body = await res.json();
  return body;
}

export async function deleteRDMDevice(userCon: tUserContext, deviceId: string) {
  if (!userCon.state.currentDevice) return;

  console.log(deviceId);
  const res = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state.currentDevice._id}/deleteRDMDevice`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        deviceId: deviceId,
      }),
    },
  );

  if (!res.ok) throw new Error('Request Unsuccessful');
  return res.ok;
}
