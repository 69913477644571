import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { UserContext } from '../context/UserStateManager';
import DeviceSettings, {
  CustomerControlSettings,
  EventSettings,
} from '../devices/DeviceSettingsObject';
import { OrgRole, SysRole } from '../Enums';
import TabNavigation from '../navigation/TabNavigation';
import AdvancedSettingsForm from './AdvancedSettingsForm';
import DeviceEventsList from './DeviceEventsList';
import TriggerList from './TriggerList';
import DeviceStatusPage from './DeviceStatusPage';
import SystemSettingsForm from './SystemSettingsForm';
import MusicControlForm from './MusicControlForm';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import CustomerControlSettingsForm from './CustomerControlSettings';
import { MoodLighting } from './MoodLighting';
import MD5 from 'crypto-js/md5';
import { Dialog, Paper, Typography } from '@mui/material';
import DeviceComments from './DeviceComments';
import { Troubleshooting } from '../troubleshooting/Troubleshooting';
import {
  DEFAULT_DEVICE_TYPE_WORDS_NORMAL,
  DEVICE_TYPE_CHECK,
  OTHER_DEVICE_TYPE_WORDS_NORMAL,
} from '../../constants';
import RDMPage from './RDM/RDMPage';

export const DeviceSettingsContext = createContext({
  configuration: {} as DeviceSettings,
  setConfiguration: function () {} as (val: DeviceSettings) => void,
  usedInputs: [] as number[],
  updating: false,
});

export const getUsedInputs = (configuration: DeviceSettings) => {
  let used = [] as number[];

  if (
    configuration.settings.dimming.condition &&
    'input' in configuration.settings.dimming.condition
  ) {
    used.push(configuration.settings.dimming.condition.input);
  }
  if (
    configuration.settings.maintenanceMode.condition &&
    'input' in configuration.settings.maintenanceMode.condition
  ) {
    used.push(configuration.settings.maintenanceMode.condition.input);
  }
  if (
    configuration.settings.moodShowEnable.condition &&
    'input' in configuration.settings.moodShowEnable.condition
  ) {
    used.push(configuration.settings.moodShowEnable.condition.input);
  }
  if (
    configuration.settings.secondaryMoodShowEnable &&
    configuration.settings.secondaryMoodShowEnable.condition &&
    'input' in configuration.settings.secondaryMoodShowEnable.condition
  ) {
    used.push(configuration.settings.secondaryMoodShowEnable.condition.input);
  }
  if (
    configuration.settings.systemEnable.condition &&
    'input' in configuration.settings.systemEnable.condition
  ) {
    used.push(configuration.settings.systemEnable.condition.input);
  }
  if (
    configuration.musicControl.musicShowEnable.condition &&
    'input' in configuration.musicControl.musicShowEnable.condition
  ) {
    used.push(configuration.musicControl.musicShowEnable.condition.input);
  }
  configuration.triggers.forEach((trigger) => {
    if (trigger.condition && 'input' in trigger.condition) {
      used.push(trigger.condition.input);
    }
  });
  return used;
};

type props = {
  getDevices: Function;
  updateDevice: Function;
};

export function DevicePage({ getDevices, updateDevice }: props) {
  const userCon = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);
  const { pathID } = useParams<{ pathID?: string }>();
  const loaded = useRef(false);
  const [configuration, setConfiguration] = useState<DeviceSettings | null>(
    null,
  );

  useEffect(() => {
    if (configuration) return;
    if (userCon.state.currentDevice?.configuration)
      setConfiguration(
        userCon.state.currentDevice.configuration as DeviceSettings,
      );
  }, [userCon, configuration]);

  useEffect(() => {
    if (!configuration) return;
    const updateDeviceSettings = async () => {
      setUpdating(true);
      const configurationDto: any = JSON.parse(JSON.stringify(configuration));
      if (userCon.state.user.role === SysRole.SYSTEM_USER) {
        delete configurationDto.settings.pixelMap;
        delete configurationDto.settings.zones;
      }
      const settingsHash = MD5(
        JSON.stringify(userCon.state.currentDevice?.configuration),
      ).toString();
      let res = await fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL +
          '/devices/' +
          (userCon.state.user.role === SysRole.SYSTEM_USER
            ? 'settings/'
            : 'advanced/settings/') +
          userCon.state.currentDevice?._id,
        {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + userCon.state.jwtToken,
          },
          body: JSON.stringify({
            configuration: configurationDto,
            hash: settingsHash,
          }),
        },
      );
      //refresh page if changes will conflict with settings in DB
      if (res.status === 409) {
        setOpen(true);
        await getDevices();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }
      if (res.status === 200) {
        await getDevices();
      }
      setUpdating(false);
      return res.status;
    };
    if (loaded.current) updateDeviceSettings();
    else loaded.current = true;
  }, [configuration]);

  // make sure context matches url
  if (pathID !== userCon.state.currentDevice?._id) {
    return <Redirect to="/controller" />;
  }

  if (!configuration) return <div></div>;

  let content = [
    {
      title: 'Status',
      component: <DeviceStatusPage />,
    },
  ];

  content.push({
    title: 'Settings',
    component: <SystemSettingsForm />,
  });

  content.push({
    title:
      configuration.controllerType == DEVICE_TYPE_CHECK
        ? DEFAULT_DEVICE_TYPE_WORDS_NORMAL
        : OTHER_DEVICE_TYPE_WORDS_NORMAL,
    component: <MoodLighting setConfiguration={setConfiguration} />,
  });

  if (userCon.state.currentDevice?.musicEnabled) {
    content.push({
      title: 'Music Control',
      component: <MusicControlForm />,
    });
  }

  content.push({
    title:
      configuration.controllerType == DEVICE_TYPE_CHECK
        ? 'Process Highlighting'
        : 'Trigger Setup',
    component: <TriggerList />,
  });

  content.push({
    title: 'Events',
    component: (
      <DeviceEventsList
        events={configuration.events}
        setEvents={(events: EventSettings[]) =>
          setConfiguration({ ...configuration, events })
        }
      />
    ),
  });

  if (
    userCon.state.user.role !== SysRole.SYSTEM_USER ||
    userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN
  ) {
    if (userCon.state.currentDevice?.ccEnabled) {
      content.push({
        title: 'CUSTOMER CONTROL',
        component: (
          <CustomerControlSettingsForm
            setCustomerControlSettings={(settings: CustomerControlSettings) =>
              setConfiguration({
                ...configuration,
                customerControl: settings,
              })
            }
          />
        ),
      });
    }

    content.push({
      title: 'ADVANCED',
      component: <AdvancedSettingsForm updateDevice={updateDevice} />,
    });
  }

  if (
    userCon.state.user.role === SysRole.SYSTEM_ADMIN ||
    userCon.state.user.role === SysRole.SYSTEM_MANAGER
  ) {
    content.push({
      title: 'REMOTE DEVICE MANAGEMENT (RDM)',
      component: <RDMPage />,
    });
  }

  if (userCon.state.user.role !== SysRole.SYSTEM_USER) {
    content.push({
      title: 'Comments',
      component: <DeviceComments />,
    });
  }

  if (userCon.state.user.role === SysRole.SYSTEM_ADMIN) {
    content.push({
      title: 'Troubleshooting',
      component: <Troubleshooting />,
    });
  }

  return (
    <div>
      <DeviceSettingsContext.Provider
        value={{
          configuration,
          setConfiguration,
          usedInputs: getUsedInputs(configuration),
          updating,
        }}
      >
        <Dialog open={open}>
          <Paper>
            <Typography align="center" variant="h2" gutterBottom>
              Warning!
            </Typography>
            <Typography align="center" variant="body1">
              Your changes were not saved since someone updated the same
              controller. Your screen will automatically be updated with the
              latest changes and any updates will need to be resubmitted.
            </Typography>
          </Paper>
        </Dialog>
        <TabNavigation
          title={'Controllers > ' + userCon.state.currentDevice?.name}
          content={content}
        />
      </DeviceSettingsContext.Provider>
    </div>
  );
}

export default DevicePage;
