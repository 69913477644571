import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  refreshUserContext,
  tUserContext,
  UserContext,
} from '../context/UserStateManager';
import ConfirmationModal from '../common/ConfirmationModal';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { EditOutlined } from '@mui/icons-material';
import EditLineupForm from '../common/EditLineupForm';
import { ShowConfiguration } from '../devices/DeviceSettingsObject';
import { useMutation, useQueryClient } from 'react-query';
import { LineupType } from '../Enums';

export interface ShowLineup {
  _id: string;
  name: string;
  lineup: ShowConfiguration[];
}

async function addOrgLineup(
  userCon: tUserContext,
  lineup: ShowLineup,
  lineupType: string,
) {
  let url = lineupType === LineupType.MOOD_LIGHTING ? 'lineup' : 'musiclineup';

  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/${url}`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: lineup.name,
        lineup: lineup.lineup,
      }),
    },
  );
  if (!resp.ok) {
    if (resp.status === 400) {
      throw new Error('Error adding new lineup.');
    }
    throw new Error('Request unsuccessful');
  }
}

async function updateOrgLineup(
  userCon: tUserContext,
  lineup: ShowLineup,
  lineupType: string,
) {
  let url = 'musiclineup';

  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/${url}/${lineup._id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
      body: JSON.stringify({
        name: lineup.name,
        lineup: lineup.lineup,
      }),
    },
  );
  if (resp.ok) {
    return resp.json();
  } else {
    if (resp.status === 400) {
      throw new Error('That name is already in use.');
    }
    throw new Error('Request unsuccessful');
  }
}

async function deleteOrgLineup(
  lineupId: string,
  userCon: tUserContext,
  lineupType: string,
) {
  let url = lineupType === LineupType.MOOD_LIGHTING ? 'lineup' : 'musiclineup';

  if (!lineupId) return;
  let resp = await fetchLogoutOn401(
    userCon,
    `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org?._id}/${url}/${lineupId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userCon.state.jwtToken,
      },
    },
  );
  if (resp.ok) refreshUserContext(userCon.state, userCon.setState);
  else console.error('delete failed');
}

export default function OrgMusicLineupList() {
  const userCon = useContext(UserContext);
  const targetId = useRef('');
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState({
    open: false,
    showLineup: {} as ShowLineup | null,
    id: '',
  });
  const queryClient = useQueryClient();
  const lineupType = LineupType.MUSIC_LIGHTING;

  const formDetailsObject = {
    title: 'Music Show Lineups',
    formTitle: 'Music Show',
    description: 'Lineups that can be used across the entire organization',
    promptMessage: 'Remove Music Show Lineup',
  };

  const updateMutation = useMutation(
    (lineup: ShowLineup) => updateOrgLineup(userCon, lineup, lineupType),
    {
      onSuccess: () => queryClient.invalidateQueries('org-lineups'),
    },
  );

  const addMutation = useMutation(
    (lineup: ShowLineup) => addOrgLineup(userCon, lineup, lineupType),
    {
      onSuccess: () => queryClient.invalidateQueries('org-lineups'),
    },
  );

  return (
    <div>
      <Paper>
        <Typography variant="h3">{formDetailsObject.title}</Typography>
        <Typography>{formDetailsObject.description}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={1}></TableCell>
              <TableCell width={1}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(userCon.state.currentOrg?.org.orgMusicLineups ?? []).map(
              ({ _id, name }) => (
                <TableRow key={_id}>
                  <TableCell width={1} style={{ padding: '2px' }}>
                    <IconButton
                      onClick={() => {
                        targetId.current = _id;
                        setEditFormOpen({
                          showLineup:
                            userCon.state.currentOrg?.org.orgMusicLineups?.find(
                              ({ _id }) => _id === targetId.current,
                            ) || null,
                          open: true,
                          id: _id,
                        });
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                  </TableCell>
                  <TableCell width={1} style={{ padding: '2px' }}>
                    <IconButton
                      onClick={() => {
                        targetId.current = _id;
                        setRemoveModalOpen(true);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ padding: '12px' }}>{name}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
        <div
          style={{
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setEditFormOpen({
                ...editFormOpen,
                showLineup:
                  userCon.state.currentOrg?.org.orgMusicLineups?.find(
                    ({ _id }) => _id === targetId.current,
                  ) || null,
                open: true,
              })
            }
            style={{ margin: '32px auto' }}
          >
            Add Lineup
          </Button>
        </div>
      </Paper>
      <ConfirmationModal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        onConfirm={() => {
          deleteOrgLineup(targetId.current, userCon, lineupType).then(() => {
            setRemoveModalOpen(false);
            targetId.current = '';
          });
        }}
        title={formDetailsObject.promptMessage}
        message="Are you sure you want to remove this lineup from this organization?"
        confirmText="Remove"
      />
      <Dialog
        fullScreen
        open={editFormOpen.open}
        onClose={() =>
          setEditFormOpen({ showLineup: null, open: false, id: '' })
        }
      >
        <EditLineupForm
          lineupType={lineupType}
          title={formDetailsObject.formTitle}
          closeDialog={() => {
            setEditFormOpen({ showLineup: null, open: false, id: '' });
            refreshUserContext(userCon.state, userCon.setState);
          }}
          showLineup={editFormOpen.showLineup}
          submit={async (lineup) => {
            try {
              if (editFormOpen.showLineup) {
                await updateMutation.mutateAsync(lineup);
              } else {
                await addMutation.mutateAsync(lineup);
              }
              return { success: true };
            } catch (e: any) {
              return { success: false, message: e.message };
            }
          }}
        />
      </Dialog>
    </div>
  );
}
