import { rgbToHex } from '@mui/material';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { tUserContext } from '../context/UserStateManager';
import { COLORS, WHITE } from '../Enums';
import { CustomColor, GroupEvents, GroupShowLineup } from './orgTypes';
import tinycolor from 'tinycolor2';
import { hsvaToHex, hsvaToRgba } from '@uiw/color-convert';

export const addOrgLineup = async (
  userContext: tUserContext,
  lineupObj: GroupShowLineup,
  primaryOrSecondary: string,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/lineup/' +
      primaryOrSecondary,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(lineupObj),
    },
  );
  if (!resp.ok) {
    throw new Error('Adding lineup failed!');
  }
};
export const updateOrgLineup = async (
  userContext: tUserContext,
  lineupObj: GroupShowLineup,
  primaryOrSecondary: string,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/lineup/' +
      lineupObj._id +
      '/' +
      primaryOrSecondary,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(lineupObj),
    },
  );
  if (!resp.ok) {
    throw new Error('Updating lineup failed!');
  }
};

export const updateLineupOverride = async (
  userContext: tUserContext,
  lineupIdOrRemove: string,
  groups: string[],
  primaryOrSecondary: string,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/lineup/' +
      lineupIdOrRemove +
      '/override/' +
      primaryOrSecondary,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(groups),
    },
  );
  if (!resp.ok) {
    throw new Error('Updating lineup override failed!');
  }
};
export const deleteOrgLineup = async (
  userContext: tUserContext,
  lineupId: string,
  primaryOrSecondary: string,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/lineup/' +
      lineupId +
      '/' +
      primaryOrSecondary,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
    },
  );
  if (resp.ok) {
    return resp.json();
  }
  throw new Error('Deleting lineup failed!');
};

export const addOrgEvent = async (
  userContext: tUserContext,
  eventObj: GroupEvents,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/event',
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(eventObj),
    },
  );
  if (!resp.ok) {
    throw new Error('Adding event failed!');
  }
};

export const updateOrgEvent = async (
  userContext: tUserContext,
  eventObj: GroupEvents,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/event/' +
      eventObj._id,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(eventObj),
    },
  );
  if (!resp.ok) {
    throw new Error('Updating event failed!');
  }
};

export const deleteOrgEvent = async (
  userContext: tUserContext,
  eventId: string,
) => {
  const resp = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/event/' +
      eventId,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
    },
  );
  if (resp.ok) {
    return resp.json();
  }
  throw new Error('Deleting event failed!');
};

export const getOrgColors = async (userContext: tUserContext) => {
  const response = await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/colors',
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
    },
  );
  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Request unsuccessful');
  }
};

export const deleteOrgColor = async (
  userContext: tUserContext,
  selectedColor: CustomColor,
) => {
  return await fetchLogoutOn401(
    userContext,
    process.env.REACT_APP_BACKEND_URL +
      '/orgs/' +
      userContext.state.currentOrg?.org?._id +
      '/colors/' +
      selectedColor._id,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + userContext.state.jwtToken,
      },
      body: JSON.stringify(selectedColor),
    },
  );
};

export const saveOrgColor = async (
  userContext: tUserContext,
  selectedColor: CustomColor,
  hsva: {
    h: number;
    s: number;
    v: number;
    a: number;
  },
) => {
  const url =
    process.env.REACT_APP_BACKEND_URL +
    '/orgs/' +
    userContext.state.currentOrg?.org?._id +
    (selectedColor._id ? `/colors/${selectedColor._id}` : '/colors');
  return await fetchLogoutOn401(userContext, url, {
    method: selectedColor._id ? 'PATCH' : 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + userContext.state.jwtToken,
    },
    body: JSON.stringify({
      ...selectedColor,
      value: [hsvaToRgba(hsva).r, hsvaToRgba(hsva).g, hsvaToRgba(hsva).b],
      display: hsvaToHex(hsva),
    }),
  });
};

export function textColorFromBg(hex: string) {
  return tinycolor(hex).isDark() ? '#f4f7fc' : '#171725';
}
