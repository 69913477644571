export enum SysRole {
  SYSTEM_USER = 'SYSTEM_USER',
  SYSTEM_MANAGER = 'SYSTEM_MANAGER',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}
// mapping of the SysRole Enum and a display string
export const SysRoleMap = {
  [SysRole.SYSTEM_USER]: 'System User',
  [SysRole.SYSTEM_MANAGER]: 'System Manager',
  [SysRole.SYSTEM_ADMIN]: 'System Admin',
};

export enum OrgRole {
  ORG_USER = 'ORG_USER',
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_VIEWER = 'ORG_VIEWER',
}
// mapping of the OrgRole Enum and a display string
export const OrgRoleMap = {
  [OrgRole.ORG_USER]: 'Org User',
  [OrgRole.ORG_ADMIN]: 'Org Admin',
  [OrgRole.ORG_VIEWER]: 'Org Viewer',
};

export enum ControllerType {
  DEFAULT_TYPE = 'DEFAULT_TYPE',
  EDGE = 'EDGE',
}
// mapping of the ControllerType Enum and a display string
export const ControllerTypeMap = {
  [ControllerType.DEFAULT_TYPE]: 'ColorWash',
  [ControllerType.EDGE]: 'EDGE',
};

export enum ConditionType {
  INPUT_CONDITION = 'INPUT_CONDITION',
  TIME_CONDITION = 'TIME_CONDITION',
  ALWAYS_ON = 'ALWAYS_ON',
  ALWAYS_OFF = 'ALWAYS_OFF',
  SUN_CONDITION = 'SUN_CONDITION',
}

// mapping of the ConditionType Enum and a display string
export const ConditionTypeMap = {
  [ConditionType.INPUT_CONDITION]: 'Input Condition',
  [ConditionType.TIME_CONDITION]: 'Time Condition',
  [ConditionType.SUN_CONDITION]: 'Sunrise/Sunset Condition',
  [ConditionType.ALWAYS_ON]: 'Always On',
  [ConditionType.ALWAYS_OFF]: 'Always Off',
};

export enum LogType {
  STATUS = 'status',
  SETTINGS = 'settings',
  DEBUG = 'debug',
  CCEVENT = 'ccevent',
}

export const LogTypeMap = {
  [LogType.STATUS]: 'Status',
  [LogType.SETTINGS]: 'Settings',
  [LogType.DEBUG]: 'Debug',
  [LogType.CCEVENT]: 'CC Event',
};

export enum SignalLogicType {
  ACTIVE_HIGH = 'ACTIVE_HIGH',
  ACTIVE_LOW = 'ACTIVE_LOW',
  RISING_EDGE = 'RISING_EDGE',
  FALLING_EDGE = 'FALLING_EDGE',
}
// mapping of the SignalLogicType Enum and a display string
export const SignalLogicTypeMap = {
  [SignalLogicType.ACTIVE_HIGH]: 'Active High',
  [SignalLogicType.ACTIVE_LOW]: 'Active Low',
  [SignalLogicType.RISING_EDGE]: 'Rising Edge',
  [SignalLogicType.FALLING_EDGE]: 'Falling Edge',
};

export enum WhiteCeilingType {
  WHITE_OFF = 'WHITE_OFF',
  WHITE_ON = 'WHITE_ON',
}
// mapping of the WhiteCeilingType Enum and a display string
export const WhiteCeilingTypeMap = {
  [WhiteCeilingType.WHITE_OFF]: 'White Off',
  [WhiteCeilingType.WHITE_ON]: 'White On',
};

export const LineupType = {
  MOOD_LIGHTING: 'MOOD_LIGHTING' as string,
  MUSIC_LIGHTING: 'MUSIC_LIGHTING' as string,
};

export enum MusicShowName {
  JUMP = 'JUMP',
  CHASE = 'CHASE',
  SPARKLE = 'SPARKLE',
}

// mapping of the ShowName Enum and a display string
export const MusicShowNameMap = {
  [MusicShowName.JUMP]: 'Beat Jump',
  [MusicShowName.CHASE]: 'Beat Chase',
  [MusicShowName.SPARKLE]: 'Beat Sparkle',
};

// map holding the parameter requirements for different show types
// the show form, hides/shows options based on this map
export const MusicShowConfigMap = {
  [MusicShowName.JUMP]: {
    minColors: 2,
    maxColors: 10,
    speed: false,
    direction: false,
  },
  [MusicShowName.CHASE]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: true,
  },
  [MusicShowName.SPARKLE]: {
    minColors: 2,
    maxColors: 3,
    speed: false,
    direction: false,
  },
};

export enum ShowName {
  SOLID = 'SOLID',
  CHASE = 'CHASE',
  ALTERNATING_CHASE = 'ALTERNATING_CHASE',
  CURTAIN = 'CURTAIN',
  SPARKLE = 'SPARKLE',
  SPACE_MOUNTAIN = 'SPACE_MOUNTAIN',
  FIRE = 'FIRE',
  RAINBOW = 'RAINBOW',
  SMOOTH_WAVE = 'SMOOTH_WAVE',
  SCROLL = 'SCROLL',
  JUMP = 'JUMP',
  STACK = 'STACK',
  SPLIT_ALTERNATE = 'SPLIT_ALTERNATE',
  FADE = 'FADE',
  KNIGHT_RIDER = 'KNIGHT_RIDER',
}

// mapping of the ShowName Enum and a display string
export const ShowNameMap = {
  [ShowName.SOLID]: 'Solid',
  [ShowName.CHASE]: 'Chase',
  [ShowName.ALTERNATING_CHASE]: 'Alternating Chase',
  [ShowName.CURTAIN]: 'Curtain',
  [ShowName.SPARKLE]: 'Sparkle',
  [ShowName.SPACE_MOUNTAIN]: 'Space Mountain',
  [ShowName.FIRE]: 'Fire',
  [ShowName.RAINBOW]: 'Rainbow',
  [ShowName.SMOOTH_WAVE]: 'Smooth Wave',
  [ShowName.SCROLL]: 'Scroll',
  [ShowName.JUMP]: 'Jump',
  [ShowName.STACK]: 'Stack',
  [ShowName.SPLIT_ALTERNATE]: 'Split Alternate',
  [ShowName.FADE]: 'Fade',
  [ShowName.KNIGHT_RIDER]: 'Knight Rider',
};

// map holding the parameter requirements for different show types
// the show form, hides/shows options based on this map
export const ShowConfigMap = {
  [ShowName.SOLID]: {
    minColors: 1,
    maxColors: 1,
    speed: false,
    direction: false,
    id: 0,
    videoUrl: '',
  },
  [ShowName.CHASE]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: true,
    id: 1,
    videoUrl: '',
  },
  [ShowName.ALTERNATING_CHASE]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: false,
    id: 2,
    videoUrl: '',
  },
  [ShowName.CURTAIN]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: false,
    id: 3,
    videoUrl: '',
  },
  [ShowName.SPARKLE]: {
    minColors: 3,
    maxColors: 3,
    speed: true,
    direction: false,
    id: 4,
    videoUrl: '',
  },
  [ShowName.SPACE_MOUNTAIN]: {
    minColors: 2,
    maxColors: 2,
    speed: true,
    direction: true,
    id: 5,
    videoUrl: '',
  },
  [ShowName.FIRE]: {
    minColors: 0,
    maxColors: 0,
    speed: true,
    direction: false,
    id: 6,
    videoUrl: '',
  },
  [ShowName.RAINBOW]: {
    minColors: 0,
    maxColors: 0,
    speed: true,
    direction: true,
    id: 7,
    videoUrl: '',
  },
  [ShowName.SMOOTH_WAVE]: {
    minColors: 2,
    maxColors: 12,
    speed: true,
    direction: true,
    id: 8,
    videoUrl: '',
  },
  [ShowName.SCROLL]: {
    minColors: 2,
    maxColors: 5,
    speed: true,
    direction: true,
    id: 9,
    videoUrl: '',
  },
  [ShowName.JUMP]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: false,
    id: 10,
    videoUrl: '',
  },
  [ShowName.STACK]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: true,
    id: 11,
    videoUrl: '',
  },
  [ShowName.SPLIT_ALTERNATE]: {
    minColors: 2,
    maxColors: 2,
    speed: true,
    direction: false,
    id: 12,
    videoUrl: '',
  },
  [ShowName.FADE]: {
    minColors: 2,
    maxColors: 10,
    speed: true,
    direction: false,
    id: 13,
    videoUrl: '',
  },
  [ShowName.KNIGHT_RIDER]: {
    minColors: 2,
    maxColors: 2,
    speed: true,
    direction: false,
    id: 14,
    videoUrl: '',
  },
};

// Map of colors for the system
// Name is displayed on frontend
// Display is the hex code color that represents the named color
// Value is sent to the backend and eventually the device
// (values should be distinct so that the frontend can differentiate between them)
export const COLORS = [
  { name: 'Light Red', display: '#f77979', value: [255, 77, 51], _id: '1' },
  { name: 'Red', display: '#FF0000', value: [255, 0, 0], _id: '2' },
  { name: 'Orange', display: '#FF5A00', value: [255, 31, 0], _id: '3' },
  {
    name: 'Neon Orange',
    display: '#FF9D23',
    value: [255, 77, 0],
    _id: '4',
  },
  { name: 'Gold', display: '#FFD500', value: [255, 143, 0], _id: '5' },
  {
    name: 'Neon Yellow',
    display: '#FEFF00',
    value: [255, 191, 0],
    _id: '6',
  },
  { name: 'Yellow', display: '#EEEE18', value: [255, 166, 0], _id: '7' },
  {
    name: 'Yellow Green',
    display: '#AFF31E',
    value: [191, 255, 0],
    _id: '8',
  },
  {
    name: 'Neon Green',
    display: '#5EFF21',
    value: [127, 255, 0],
    _id: '9',
  },
  { name: 'Lime', display: '#16E12E', value: [64, 255, 0], _id: '10' },
  { name: 'Green', display: '#11C900', value: [0, 255, 0], _id: '11' },
  { name: 'Mint', display: '#8DFF94', value: [26, 217, 64], _id: '12' },
  {
    name: 'Aquamarine',
    display: '#00FFA7',
    value: [0, 255, 105],
    _id: '13',
  },
  { name: 'Cyan', display: '#00FDFF', value: [0, 255, 255], _id: '14' },
  { name: 'Sky Blue', display: '#10D5FF', value: [0, 191, 255], _id: '15' },
  { name: 'Baby Blue', display: '#7ACCFF', value: [0, 77, 85], _id: '16' },
  { name: 'Neon Blue', display: '#27B9FF', value: [0, 89, 255], _id: '17' },
  { name: 'Blue', display: '#006AFF', value: [0, 0, 255], _id: '18' },
  { name: 'Violet', display: '#A136FF', value: [166, 0, 255], _id: '19' },
  {
    name: 'Neo Indigo',
    display: '#C6057B',
    value: [198, 5, 123],
    _id: '20',
  },
  {
    name: 'Neon Pink',
    display: '#FF58F6',
    value: [255, 0, 127],
    _id: '21',
  },
  { name: 'Magenta', display: '#FF1988', value: [255, 0, 64], _id: '22' },
  { name: 'Pink', display: '#FF5AA6', value: [255, 13, 64], _id: '23' },
  {
    name: 'White',
    display: '#FFFFFF',
    value: [255, 227, 179],
    _id: '24',
  },
  { name: 'Off/Black', display: '#000000', value: [0, 0, 0], _id: '30' },
];

export const WHITE_VALUE = [255, 227, 179] as const;
export const WHITE = {
  name: 'White',
  display: '#FFFFFF',
  value: [255, 227, 179],
  _id: '24',
};
